/* eslint-disable max-len */
import React, { useState } from 'react';
import classnames from 'classnames';
import { MDXProvider } from '@mdx-js/react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { SprkLink } from '@sparkdesignsystem/spark-react';
import Header from '../Header';
import Menu from '../Menu';
import Footer from '../Footer';
import '../../scss/main.scss';

import SEO from '../seo';
import H1 from '../markdown-render/h1';
import H2 from '../markdown-render/h2';
import H3 from '../markdown-render/h3';
import H4 from '../markdown-render/h4';
import P from '../markdown-render/p';
import Ul from '../markdown-render/ul';
import Li from '../markdown-render/li';
import A from '../markdown-render/a';
import Table from '../markdown-render/table';
import Blockquote from '../markdown-render/blockquote';
import inlineCode from '../markdown-render/inlineCode';

const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  p: P,
  ul: Ul,
  li: Li,
  a: A,
  table: Table,
  blockquote: Blockquote,
  code: inlineCode,
};

const Layout = ({ children, initialContext, hasSideBar, location }) => {
  const [context, setContext] = useState(initialContext || 'homepage');
  const [menuVisible, setMenuVisible] = useState(false);
  const lastPartofPath = /[^/]*$/;
  const currentPagePath = location
    ? location.pathname.match(lastPartofPath)
    : 'Home';
  const currentPageName = currentPagePath
    .toString()
    .replace(/-/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');

  return (
    <StaticQuery
      query={graphql`
        {
          allSparkIconSetV14 {
            edges {
              node {
                internal {
                  content
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <div className="sprk-o-Box docs-c-SkipNav sprk-u-BackgroundColor--white">
            <SprkLink
              href="#main_content"
              additionalClasses="docs-c-SkipNav__link"
            >
              Skip to Main Content
            </SprkLink>
          </div>
          <aside className="sprk-u-BackgroundColor--blue sprk-u-AbsoluteCenter sprk-u-pam">
            <h6 className="sprk-u-Color--white">
              Spark Design System will be retired at the end of 2022. 
              Feature updates have ended. We'll continue to provide bug 
              fixes and support until Spark is retired. Questions?
              Contact 
              <a
                href="mailto:SparkDesignSystem@rocketcentral.com"
                className="docs-c-Banner--link sprk-u-mls"
              >
                SparkDesignSystem@rocketcentral.com
              </a>.
            </h6>
          </aside>
          <Header
            context={context}
            setContext={setContext}
            menuVisible={menuVisible}
            setMenuVisible={setMenuVisible}
          />
          <SEO title={currentPageName} />
          <div className="docs-layout sprk-o-CenteredColumn">
            <div
              className="sprk-u-Display--none"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: data.allSparkIconSetV14.edges[0].node.internal.content,
              }}
            />

            {hasSideBar && (
              <div
                className={classnames({
                  'docs-layout__side-bar': true,
                })}
              >
                <Menu
                  components={{}}
                  context={context}
                  setContext={setContext}
                  menuVisible={menuVisible}
                  setMenuVisible={setMenuVisible}
                />
              </div>
            )}

            <div className="docs-layout__content sprk-o-Box sprk-o-Box--large">
              <MDXProvider components={components}>
                <main id="main_content">{children}</main>
              </MDXProvider>
            </div>
          </div>
          <svg
            aria-hidden="true"
            className="sprk-u-Width-100"
            width="4296"
            height="372"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(2.67 -3.42)">
              <path
                d="m4163.69 242.68a382.72 382.72 0 0 0 -166.07 6.69l-31.4 8.33a223.88 223.88 0 0 1 -82.7 6.08c-45.34-5.15-81.68-14.52-122.8-34.65-15.14-7.41-32.23-10.9-50.3-11.58-27.86-1.06-60.28 15-78.5 29-15.91 12.27-38.79 9.1-56.09-1.11-45.7-27-113.88-39.89-186.91-39.89-72.38 0-134.32 13.54-178.46 39.17-25.54 14.83-126.81 39.4-161.54 39.83-50.27.62-151.54-22.29-192.52-31.19-30.65-6.66-61.67-11.5-93-12.54-55.14-1.84-107.85 8.85-160.7 23.4l-1.59.44c-18.43 5-35.8-2.06-52.23-9.94-27.54-13.21-54.31-27.7-83.5-37.07-30.77-9.88-63.24-12.57-95.39-13.1-44.08-.73-86.46 13.54-128.51 24.87-40.72 11-81.58 21.48-122.68 30.93-31.09 7.15-62.8 15-94.83 18.53-31.81 3.48-62.89.44-94.47-3.42-25.46-3.11-50.84-6.82-76.15-11-34-5.58-67.63-10.76-101.24-17.9a382.19 382.19 0 0 0 -93.9-8.4 325.79 325.79 0 0 0 -47.75 5.36c-19.69 3.72-39.21 11.44-59.35 14.08-26.8 3.51-54-1.11-79.31-10.18-25.09-9-46.94-23.89-73.23-31.36a385.3 385.3 0 0 0 -97.7-14.84v-1.69s-181.58 5.21-388.54 56.58c-152 37.74-175.88 9.81-221.53-8.59-35.3-14.22-94.71-27.25-131.5-33.39-82.61-13.77-205.5-10.29-348.42 35.39-200.27 55.41-211.02-17.88-211.02-17.88v140.72l1301.07.19c121.9.18 2995-1 2995-1v-105z"
                fill="#8cb5ad"
                opacity=".35"
              />
              <g fill="#a3cec6">
                <path d="m2802.8 295.46a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1.2 1.2 0 0 1 -.57.16z" />
                <path d="m2810.67 295.32a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.08 1.08 0 0 1 -.92.51z" />
                <path d="m2807 292.11a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.07a1.08 1.08 0 0 1 -1.08 1.11z" />
                <path d="m2716 329.41a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m2723.9 329.28a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.11 1.11 0 0 1 -.92.51z" />
                <path d="m2720.18 326.07a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.11a1.08 1.08 0 0 1 -1.08 1.07z" />
                <path d="m3520.8 271.46a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1.2 1.2 0 0 1 -.57.16z" />
                <path d="m3528.67 271.32a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.08 1.08 0 0 1 -.92.51z" />
                <path d="m3525 268.11a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.07a1.08 1.08 0 0 1 -1.08 1.11z" />
                <path d="m3273.8 252.46a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1.2 1.2 0 0 1 -.57.16z" />
                <path d="m3281.67 252.32a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.08 1.08 0 0 1 -.92.51z" />
                <path d="m3278 249.11a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.07a1.08 1.08 0 0 1 -1.08 1.11z" />
                <path d="m3083.8 335.46a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1.2 1.2 0 0 1 -.57.16z" />
                <path d="m3091.67 335.32a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.08 1.08 0 0 1 -.92.51z" />
                <path d="m3088 332.11a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.07a1.08 1.08 0 0 1 -1.08 1.11z" />
                <path d="m3434 305.41a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m3441.9 305.28a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.11 1.11 0 0 1 -.92.51z" />
                <path d="m3438.18 302.07a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.11a1.08 1.08 0 0 1 -1.08 1.07z" />
                <path d="m3729 262.41a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m3736.9 262.28a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.11 1.11 0 0 1 -.92.51z" />
                <path d="m3733.18 259.07a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.11a1.08 1.08 0 0 1 -1.08 1.07z" />
                <path d="m3638 351.41a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m3645.9 351.28a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.11 1.11 0 0 1 -.92.51z" />
                <path d="m3642.18 348.07a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.11a1.08 1.08 0 0 1 -1.08 1.07z" />
                <path d="m3924 312.41a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m3931.9 312.28a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.11 1.11 0 0 1 -.92.51z" />
                <path d="m3928.18 309.07a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.11a1.08 1.08 0 0 1 -1.08 1.07z" />
                <path d="m4203 282.41a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 0 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m4210.9 282.28a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.11 1.11 0 0 1 -.92.51z" />
                <path d="m4207.18 279.07a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.11a1.08 1.08 0 0 1 -1.08 1.07z" />
                <path d="m4077 340.41a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m4084.9 340.28a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.11 1.11 0 0 1 -.92.51z" />
                <path d="m4081.18 337.07a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.11a1.08 1.08 0 0 1 -1.08 1.07z" />
                <path d="m2063.72 323.48a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.92 1.65z" />
                <path d="m2071.6 323.35a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.11 1.11 0 0 1 -.92.51z" />
                <path d="m2067.88 320.14a1.08 1.08 0 0 1 -1.08-1.08v-7.06a1.08 1.08 0 0 1 2.16 0v7.1a1.08 1.08 0 0 1 -1.08 1.04z" />
                <path d="m2276.74 268.5a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 0 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.92 1.65z" />
                <path d="m2284.62 268.36a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m2280.9 265.15a1.08 1.08 0 0 1 -1.08-1.08v-7.07a1.08 1.08 0 1 1 2.16 0v7.1a1.08 1.08 0 0 1 -1.08 1.05z" />
                <path d="m1885.32 301.08a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.92 1.65z" />
                <path d="m1893.2 301a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m1889.48 297.74a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.1a1.08 1.08 0 0 1 -1.08 1.08z" />
                <path d="m1700.67 331.17a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1.17 1.17 0 0 1 -.57.16z" />
                <path d="m1708.55 331a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m1704.83 327.82a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.1a1.08 1.08 0 0 1 -1.08 1.08z" />
                <path d="m1518.93 291.81a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1.1 1.1 0 0 1 -.57.16z" />
                <path d="m1526.8 291.67a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.06 1.06 0 0 1 -.92.51z" />
                <path d="m1523.09 288.46a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.1a1.08 1.08 0 0 1 -1.08 1.08z" />
                <path d="m1389.51 324a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m1397.39 323.84a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m1393.67 320.63a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.1a1.07 1.07 0 0 1 -1.08 1.08z" />
                <path d="m1225.51 226a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 0 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m1233.39 225.84a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m1229.67 222.63a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.1a1.07 1.07 0 0 1 -1.08 1.08z" />
                <path d="m1132.51 297a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m1140.39 296.84a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m1136.67 293.63a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.1a1.07 1.07 0 0 1 -1.08 1.08z" />
                <path d="m894.51 356a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m902.39 355.84a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m898.67 352.63a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.1a1.07 1.07 0 0 1 -1.08 1.08z" />
                <path d="m835.14 311.37a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 0 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1.14 1.14 0 0 1 -.57.16z" />
                <path d="m843 311.23a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m839.3 308a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.1a1.07 1.07 0 0 1 -1.08 1.08z" />
                <path d="m555.4 244.28a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 0 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.92 1.65z" />
                <path d="m563.28 244.15a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.11 1.11 0 0 1 -.92.51z" />
                <path d="m559.56 240.94a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.1a1.08 1.08 0 0 1 -1.08 1.08z" />
                <path d="m993.51 281a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m1001.39 280.84a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m997.67 277.63a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.1a1.07 1.07 0 0 1 -1.08 1.08z" />
                <path d="m697.51 339a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 0 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m705.39 338.84a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m701.67 335.63a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.1a1.07 1.07 0 0 1 -1.08 1.08z" />
                <path d="m478.51 352a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 0 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m486.39 351.84a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m482.67 348.63a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.1a1.07 1.07 0 0 1 -1.08 1.08z" />
                <path d="m192.51 343a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m200.39 342.84a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m196.67 339.63a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.1a1.07 1.07 0 0 1 -1.08 1.08z" />
                <path d="m327.51 261a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 0 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1 1 0 0 1 -.57.16z" />
                <path d="m335.39 260.84a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.09 1.09 0 0 1 -.92.51z" />
                <path d="m331.67 257.63a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 0 1 2.16 0v7.1a1.07 1.07 0 0 1 -1.08 1.08z" />
                <path d="m2441.2 259.36a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 0 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1.1 1.1 0 0 1 -.57.16z" />
                <path d="m2449.07 259.22a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 1 1 1.84 1.14l-3.7 5.94a1.08 1.08 0 0 1 -.92.51z" />
                <path d="m2445.36 256a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.1a1.08 1.08 0 0 1 -1.08 1.08z" />
                <path d="m2466.69 314.11a1.09 1.09 0 0 1 -.92-.51l-3.7-5.94a1.08 1.08 0 1 1 1.84-1.14l3.7 5.94a1.08 1.08 0 0 1 -.35 1.49 1.1 1.1 0 0 1 -.57.16z" />
                <path d="m2474.56 314a1.08 1.08 0 0 1 -.92-1.65l3.7-5.94a1.08 1.08 0 0 1 1.84 1.14l-3.7 5.94a1.06 1.06 0 0 1 -.92.51z" />
                <path d="m2470.85 310.76a1.08 1.08 0 0 1 -1.08-1.08v-7.1a1.08 1.08 0 1 1 2.16 0v7.1a1.08 1.08 0 0 1 -1.08 1.08z" />
              </g>
              <path
                d="m2783.54 147.09c-49.32 0-99.57-23.9-149.57-71.2l.7-.74c54.46 51.52 109.16 75.17 162.61 70.3 75.7-6.91 122.54-69.86 123-70.5l.83.6c-.12.16-11.93 16.17-33 32.82-19.44 15.37-51 34.46-90.76 38.09-4.57.42-9.19.63-13.81.63z"
                fill="#cfcfcf"
              />
              <path
                d="m2785.18 152.66c-51.58 0-104.71-25.75-158.13-76.69l.71-.74c57.2 54.53 114 80.09 168.89 76 68.51-5.14 114-54.86 130.4-76l.22-.28.81.63-.22.28c-16.47 21.25-62.21 71.22-131.13 76.39q-5.73.4-11.55.41z"
                fill="#cfcfcf"
              />
              <path
                d="m2249.39 145.47c-49.74 0-100.29-24-150.49-71.48l.7-.74c54.47 51.52 109.34 75.29 163 70.62 76.08-6.6 123.47-69.14 123.94-69.77l.82.61c-.12.16-12.06 16.06-33.3 32.57-19.6 15.24-51.37 34.13-91.37 37.6q-6.62.6-13.3.59z"
                fill="#cfcfcf"
              />
              <path
                d="m2251.06 151.05c-52 0-105.46-25.85-159.09-77l.71-.74c57.42 54.74 114.6 80.4 170 76.27 71.23-5.32 118.22-59.14 130.92-75.51l.81.63c-12.77 16.45-60 70.55-131.65 75.9q-5.87.46-11.7.45z"
                fill="#cfcfcf"
              />
              <path
                d="m1714.78 143.94c-50.11 0-100.91-24.06-151.22-71.65l.7-.74c54.41 51.47 109.37 75.3 163.34 70.84 76.59-6.34 124.62-68.7 125.09-69.33l.82.62c-.12.16-12.23 16-33.68 32.45-19.81 15.17-51.87 34-92.13 37.28q-6.45.53-12.92.53z"
                fill="#cfcfcf"
              />
              <path
                d="m1716.4 149.38c-52.38 0-106.09-25.91-159.85-77.16l.71-.74c57.58 54.89 115.07 80.62 170.86 76.44 72.11-5.39 118.83-58.79 131.43-75l.81.63c-12.67 16.32-59.64 70-132.17 75.42-3.92.26-7.86.41-11.79.41z"
                fill="#cfcfcf"
              />
              <path
                d="m1180.91 142.08c-49.46 0-99.84-24-150-71.39l.7-.74c54.54 51.59 109.33 75.31 162.85 70.5 75.58-6.79 122.39-69.36 122.85-70l.82.61c-.12.16-11.91 16.07-32.94 32.61-19.41 15.27-50.89 34.22-90.64 37.79-4.5.41-9.07.62-13.64.62z"
                fill="#cfcfcf"
              />
              <path
                d="m1182.73 148.13c-51.57 0-104.93-26-158.79-77.32l.71-.74c56.9 54.25 113.22 80.06 167.36 76.76 33.5-2.05 83.41-16.67 131-74.69l1.3-1.58.78.66-1.29 1.57c-47.82 58.3-98 73-131.73 75.06q-4.66.29-9.34.28z"
                fill="#cfcfcf"
              />
              <path
                d="m3051.3 147.8c-49.82 0-100.69-24.25-151.42-72.24l.7-.74c55 52.06 110.17 76.07 163.92 71.39 74.44-6.49 120.25-67 120.71-67.64l.82.61c-.11.15-11.66 15.56-32.3 31.55-19.05 14.77-50 33.08-89.13 36.5-4.42.37-8.86.57-13.3.57z"
                fill="#cfcfcf"
              />
              <path
                d="m3054.41 153.5a172.83 172.83 0 0 1 -55.11-9.32c-35.17-11.79-71-34.9-106.42-68.7l.71-.74c35.34 33.69 71 56.73 106 68.47 29.29 9.81 58.36 11.84 86.42 6 65.76-13.64 103.28-64.36 106-70.7l.94.4c-2.71 6.4-40.52 57.58-106.7 71.3a156.21 156.21 0 0 1 -31.84 3.29z"
                fill="#cfcfcf"
              />
              <path
                d="m2401 69.06v-1.14h-3.4v1.14h-5.22v-1.14h-3.38v1.14h-24.34v-1.14h-3.4v1.14h-5.26v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.22h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15v201.21a18.27 18.27 0 0 1 9.56-.41v-200.8h4.43v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.74v1.09h1.21v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m2668.93 70.12v-1.12h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.17v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.21h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15v203.2a24.06 24.06 0 0 1 7.93-1.27c.55 0 1.09 0 1.63.05v-202h4.43v1.16h1.21v-1.14h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.74v1.09h1.21v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m2934.68 69.69v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.19h5.77v1.11h1.18v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.21l-.18 199.43c2.89-.3 8.32 1.12 9.74 1.43v-200.86h4.44v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m2133.75 68.29v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.39v1.14h-5.22v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.19h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.1v206.18a4.53 4.53 0 0 0 .07.74 55.71 55.71 0 0 1 7.37-.47h2.09a3 3 0 0 0 0-.31v-206.14h4.47v1.16h1.21v-1.16h5.79v1.11h1.21v-1.11h1.6v1.14h1.19v-1.14h5.77v1.09h1.23v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m1866.91 67.63v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.19h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15v201.54a1.45 1.45 0 0 0 1.71 1.41 35.31 35.31 0 0 1 5.67-.45c.74 0 1.47 0 2.18.07v-202.57h4.43v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.81v1.09h1.21v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m1598.4 66.29v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.38v-1.14h-3.4v1.14h-5.22v-1.14h-3.38v1.14h-5.12v5h1.66v1.16h1.21v-1.19h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15s-.65 190-.33 204a.42.42 0 0 0 .63.36c2.24-1.39 5.73-.5 9.26-.92v-203.43h4.43v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m1331.62 65.19v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.22v-1.19h5.77v1.11h1.23v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15v202.45c.54 0 1.09-.05 1.65-.05 3 0 5.58-.52 7.81.22l.09-202.61h4.43v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m2256.9 127.13c-1.45 0-2.91 0-4.37-.07-59.89-1.87-117.08-43.39-123.51-53l.85-.57c2.49 3.71 17.29 16.27 40.22 28.5 19.47 10.38 49.58 23 82.47 24 41.67 1.3 76.68-16.1 104-51.72l.81.62c-26.58 34.69-60.37 52.24-100.47 52.24z"
                fill="#cfcfcf"
              />
              <path
                d="m2240.31 127.07c-37.56 0-72.61-17.82-104.29-53.06l.76-.68c33.84 37.64 71.52 55.27 112 52.42 28.81-2 53.28-14.31 68.73-24.25 18.03-11.6 29.49-23.5 31.92-27.15l.85.57c-6.67 9.93-49 48.14-101.45 51.85q-4.27.3-8.52.3z"
                fill="#cfcfcf"
              />
              <path
                d="m1982.39 145c-49.7 0-100.24-24-150.42-71.48l.7-.74c54.48 51.54 109.33 75.3 163 70.62 76-6.63 123.3-69.16 123.77-69.79l.82.61c-.12.16-12 16.06-33.25 32.57-19.57 15.24-51.3 34.14-91.26 37.63q-6.64.52-13.36.58z"
                fill="#cfcfcf"
              />
              <path
                d="m1983.64 148.41c-52 0-105.26-25.13-158.5-74.85l.7-.75c57.41 53.61 114.79 78.52 170.55 74.07 74.22-5.94 121.36-62.14 130.06-73.35l.81.63c-8.74 11.27-56.14 67.77-130.79 73.74q-6.42.51-12.83.51z"
                fill="#cfcfcf"
              />
              <path
                d="m1983 125.45q-5.33 0-10.74-.41c-58-4.39-105.06-43.69-110.22-51.38l.85-.57c5.12 7.62 51.77 46.58 109.45 50.93 43.85 3.31 83.22-13.68 117-50.5l.75.69c-31.27 34.03-67.25 51.24-107.09 51.24z"
                fill="#cfcfcf"
              />
              <path
                d="m1975 125.81c-37.32 0-72.93-17.48-106-52.09l.74-.71c35.89 37.56 74.76 54.83 115.54 51.33 28.15-2.42 51.62-14.52 66.35-24.24 17.12-11.3 27.9-22.77 30.44-26.55l.85.57c-6.73 10-46.59 46.86-97.55 51.24-3.37.3-6.89.45-10.37.45z"
                fill="#cfcfcf"
              />
              <path
                d="m1723 125.77c-1.46 0-2.93 0-4.41-.07-33.44-1-64.11-13.87-84-24.44-23.44-12.49-38.58-25.34-41.14-29.14l.85-.57c2.5 3.73 17.49 16.41 40.77 28.81 19.75 10.52 50.27 23.28 83.51 24.32 41.77 1.31 76.71-16 103.88-51.47l.81.62c-26.41 34.48-60.15 51.94-100.27 51.94z"
                fill="#cfcfcf"
              />
              <path
                d="m1716.06 125.47c-37.42 0-76.22-17.84-115.56-53.19l.68-.76c44.18 39.7 87.64 57.21 129.16 52.05 54.3-6.75 84.81-50.12 85.1-50.56l.85.57c-8.22 12.24-39.66 45.26-85.83 51a117.32 117.32 0 0 1 -14.4.89z"
                fill="#cfcfcf"
              />
              <path
                d="m2516.88 146.57c-50 0-100.74-24-151-71.59l.7-.74c54.42 51.48 109.32 75.29 163.23 70.76 40-3.35 71.78-22.06 91.43-37.17 21.31-16.38 33.32-32.18 33.44-32.33l.82.62c-.12.16-12.19 16-33.61 32.51-19.77 15.2-51.77 34-92 37.4-4.31.36-8.66.54-13.01.54z"
                fill="#cfcfcf"
              />
              <path
                d="m2518.59 152.05c-52.33 0-106-25.9-159.74-77.14l.71-.74c57.56 54.87 115 80.59 170.73 76.42 72-5.38 118.75-58.85 131.37-75.1l.81.63c-12.68 16.34-59.69 70.08-132.1 75.5q-5.88.38-11.78.43z"
                fill="#cfcfcf"
              />
              <path
                d="m2524.91 128.15c-1.46 0-2.92 0-4.4-.07-60.4-1.89-118-43.7-124.51-53.34l.85-.57c2.5 3.72 17.41 16.36 40.57 28.69 19.64 10.47 50 23.16 83.13 24.2 41.75 1.3 76.71-16 103.93-51.56l.81.62c-26.48 34.54-60.23 52.03-100.38 52.03z"
                fill="#cfcfcf"
              />
              <path
                d="m2518.31 128c-37.3 0-76-17.83-115.34-53.16l.68-.76c44.14 39.66 87.49 57.17 128.84 52 45.52-5.66 76.68-38.47 84.84-50.64l.85.57c-8.24 12.27-39.67 45.37-85.57 51.08a113.56 113.56 0 0 1 -14.3.91z"
                fill="#cfcfcf"
              />
              <path
                d="m2790.09 128.15c-1.45 0-2.9 0-4.36-.07-63-2-116.93-45.06-121.65-52.09l.85-.57c2.46 3.67 17 16.06 39.57 28.06 19.14 10.19 48.77 22.56 81.27 23.57 41.72 1.3 76.82-16.21 104.29-52.06l.81.62c-26.72 34.88-60.61 52.54-100.78 52.54z"
                fill="#cfcfcf"
              />
              <path
                d="m2774.27 128.62c-37 0-71.7-17.7-103.17-52.7l.76-.68c33.6 37.37 70.82 54.89 110.66 52.07 55.91-4 96.38-46.07 100.56-52.28l.85.57c-4.21 6.27-45 48.76-101.34 52.73-2.79.2-5.59.29-8.32.29z"
                fill="#cfcfcf"
              />
              <path
                d="m1447.06 142.72c-49.7 0-100.25-24-150.46-71.51l.7-.74c54.51 51.56 109.38 75.33 163.06 70.65 75.91-6.62 123.13-69.06 123.6-69.69l.82.61c-.12.16-12 16-33.19 32.52-19.54 15.22-51.22 34.09-91.13 37.58q-6.69.57-13.4.58z"
                fill="#cfcfcf"
              />
              <path
                d="m1448.87 148.25c-51.95 0-105.4-25.87-159.07-77l.71-.74c57.45 54.77 114.62 80.46 169.9 76.32 32.12-2.4 62.89-14.73 91.47-36.65a218.51 218.51 0 0 0 39-38.7l.43.28.38.34a219.49 219.49 0 0 1 -39.19 38.9c-28.74 22-59.7 34.44-92 36.86q-5.8.4-11.63.39z"
                fill="#cfcfcf"
              />
              <path
                d="m1459.44 126c-33.8 0-66.19-13-87.55-23.95-24.7-12.69-42.24-26.63-45.13-30.86l.84-.58c2.83 4.16 20.2 17.92 44.75 30.53 21.59 11.09 54.47 24.24 88.63 23.82 39.06-.46 70.33-18.43 92.93-53.39l.86.55c-22.77 35.26-54.35 53.38-93.77 53.88z"
                fill="#cfcfcf"
              />
              <path
                d="m1438.45 124.32c-37.75 0-72.91-17.86-104.71-53.22l.76-.68c34.88 38.79 73.82 56.38 115.73 52.31 52.11-5.07 92.09-43.08 96.72-50.85l.88.52c-4.68 7.84-45 46.23-97.5 51.34q-5.99.58-11.88.58z"
                fill="#cfcfcf"
              />
            </g>
            <path
              d="m2121.73 69.84v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.78v1.1h1.21v-1.1h1.97v-4.96h-5.27v-1.15h-3.4v1.15h-5.21v-1.15h-3.4v1.15h-24.34v-1.15h-3.4v1.15h-5.22v-1.15h-3.39v1.15h-5.12v4.96h1.66v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.14h1.21v-1.14h5.77v1.1h1.21v-1.1h5.15l.03 4.3h9.56l-.03-4.3z"
              fill="#404040"
            />
            <path
              d="m1854.88 69.19v1.15h1.21v-1.15h5.78v1.09h1.21v-1.09h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h1.98v-4.97h-5.27v-1.14h-3.4v1.14h-5.22v-1.14h-3.39v1.14h-24.35v-1.14h-3.39v1.14h-5.22v-1.14h-3.4v1.14h-5.12v4.97h1.67v1.15h1.21v-1.15h5.77v1.09h1.21v-1.09h1.6v1.14h1.21v-1.14h5.78v1.09h1.21v-1.09h5.15v4.14h9.56v-4.14z"
              fill="#404040"
            />
            <path
              d="m1586.38 67.86v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h1.61v1.14h1.21v-1.14h5.77v1.08h1.21v-1.08h1.97v-4.98h-5.27v-1.14h-3.39v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-5.12v4.98h1.66v1.14h1.21v-1.14h5.78v1.09h1.2v-1.09h1.61v1.14h1.21v-1.14h5.77v1.08h1.21v-1.08h5.15l.01 5.53h9.56l-.01-5.53z"
              fill="#404040"
            />
            <path
              d="m2656.91 71.67v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.78v1.1h1.21v-1.1h1.97v-4.97h-5.27v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.39v1.14h-5.12v4.97h1.66v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.14h1.21v-1.14h5.77v1.1h1.21v-1.1h5.15v4.64h9.56v-4.64z"
              fill="#404040"
            />
            <path
              d="m2388.95 70.61v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.78v1.09h1.21v-1.09h1.97v-4.97h-5.27v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.39v1.14h-5.12v4.97h1.66v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15l-.05 4.11h9.56l.05-4.11z"
              fill="#404040"
            />
            <path
              d="m1319.59 66.75v1.16h1.21v-1.16h5.78v1.09h1.21v-1.09h1.6v1.14h1.21v-1.14h5.77v1.08h1.21v-1.08h1.98v-4.98h-5.27v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-24.35v-1.14h-3.39v1.14h-5.22v-1.14h-3.4v1.14h-5.12v4.98h1.67v1.16h1.21v-1.16h5.77v1.09h1.21v-1.09h1.6v1.14h1.21v-1.14h5.78v1.08h1.21v-1.08h5.15v4.5l9.5-.05.06-4.45z"
              fill="#404040"
            />
            <path
              d="m475.62 1.74-13.51 24.74 10.34-.53-2.11 23.27 12.82-29.56-9.68.5z"
              fill="#eda302"
            />
            <path
              d="m508.03 27.66-15.11 8.76 5.66 3.05-8.6 11.66 16.29-11.55-5.29-2.85z"
              fill="#eda302"
            />
            <path
              d="m441.22 25.82.78 17.45 5.32-3.61 6.35 13.03-2.7-19.79-4.97 3.37z"
              fill="#eda302"
            />
            <path
              d="m877.54 142.09c-49.32 0-99.57-23.9-149.57-71.2l.7-.74c54.46 51.52 109.18 75.17 162.6 70.3 75.69-6.9 122.55-69.86 123-70.5l.83.6c-.12.16-11.93 16.17-33 32.82-19.44 15.37-51 34.46-90.76 38.09-4.56.42-9.18.63-13.8.63z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m879.18 147.66c-51.57 0-104.71-25.75-158.13-76.69l.71-.74c57.2 54.53 114 80.1 168.89 76 68.51-5.14 114-54.86 130.4-76l.22-.28.81.63-.22.28c-16.47 21.25-62.21 71.22-131.13 76.39q-5.73.4-11.55.41z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m343.42 140.76c-49.82 0-100.44-24-150.68-71.55l.7-.74c54.48 51.53 109.36 75.33 163.14 70.71 76.12-6.54 123.58-69 124.05-69.59l.82.61c-.12.16-12.08 16-33.34 32.5-19.62 15.2-51.42 34.05-91.44 37.49-4.4.38-8.83.57-13.25.57z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m345 146.3c-52.11 0-105.64-25.87-159.3-77l.71-.74c57.49 54.76 114.75 80.44 170.23 76.28 71.46-5.34 118.36-59 131-75.36l.81.63c-12.74 16.41-59.89 70.38-131.78 75.76q-5.79.42-11.67.43z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m1146.52 143.64a159.22 159.22 0 0 1 -28.19-2.55c-41.21-7.41-83.15-31.07-124.65-70.34l.7-.74c51.36 48.58 130.76 98.08 217.34 57.95 43.83-20.31 68.31-54.78 68.19-57l1-.06c.21 3.37-24.89 37.51-68.27 57.79a155 155 0 0 1 -66.12 14.95z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m1147 147.89a171.71 171.71 0 0 1 -52.27-8.39c-35.74-11.39-72-34.5-107.91-68.69l.71-.74c35.75 34.09 71.92 57.12 107.51 68.46 29.67 9.45 59.13 10.88 87.59 4.24 62-14.47 101.14-63.33 104.39-72.2l1 .35c-3.28 9-42.69 58.28-105.11 72.84a157.74 157.74 0 0 1 -35.91 4.13z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m495 64.06v-1.14h-3.4v1.14h-5.22v-1.14h-3.38v1.14h-24.39v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.22h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15v201.21a18.27 18.27 0 0 1 9.56-.41v-200.8h4.43v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.74v1.09h1.21v-1.09h2v-5z"
              fill="#404040"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m762.93 65.12v-1.12h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.17v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.21h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15l.17 212.4a24.06 24.06 0 0 1 7.93-1.27h1.63l-.17-211.18h4.43v1.16h1.21v-1.11h5.77v1.11h1.21v-1.11h1.55v1.14h1.21v-1.14h5.79v1.09h1.21v-1.09h2v-5z"
              fill="#404040"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m1028.68 64.69v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.19h5.77v1.11h1.18v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.21l-.18 199.43c2.89-.3 8.32 1.12 9.74 1.43v-200.86h4.44v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h2v-5z"
              fill="#404040"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m227.75 63.29v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.33v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.19h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15v206.18a4.53 4.53 0 0 0 .07.74 55.71 55.71 0 0 1 7.37-.47h2.09a3 3 0 0 0 0-.31v-206.14h4.42v1.16h1.21v-1.16h5.79v1.11h1.21v-1.11h1.6v1.14h1.19v-1.14h5.77v1.09h1.23v-1.09h2v-5z"
              fill="#404040"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m350.9 122.13c-1.45 0-2.91 0-4.38-.07-59.89-1.87-117.08-43.39-123.51-53l.85-.57c2.49 3.71 17.29 16.27 40.22 28.5 19.47 10.38 49.58 23 82.47 24 41.71 1.3 76.68-16.1 104-51.72l.81.62c-26.57 34.69-60.36 52.24-100.46 52.24z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m334.31 122.07c-37.56 0-72.61-17.82-104.31-53.07l.76-.68c33.84 37.64 71.52 55.27 112 52.42 28.81-2 53.28-14.31 68.73-24.25 18.04-11.59 29.51-23.49 31.93-27.14l.85.57c-6.67 9.93-49 48.14-101.45 51.85q-4.26.3-8.51.3z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m76.53 139.94c-26.12 0-52.69-6.67-79.14-19.89l.46-.91c29.61 14.79 59.37 21.34 88.44 19.46 23.26-1.5 46.14-8.37 68-20.41 37.27-20.52 59-49.35 59.17-49.64l.82.61c-.22.29-22 29.27-59.46 49.9-22 12.12-45 19-68.45 20.55q-4.91.33-9.84.33z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m77.76 143.41c-26.44 0-53.46-6.55-80.43-19.52l.44-.92c29.54 14.2 59.13 20.67 87.93 19.22a166.1 166.1 0 0 0 68.44-19 210.34 210.34 0 0 0 66.29-54.67l.81.63a211.56 211.56 0 0 1 -66.63 55c-22.14 11.45-45.31 17.85-68.86 19.06-2.65.13-5.32.2-7.99.2z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m77.26 120.4c-31.68 0-59.76-11-79.08-21.2l.48-.9c39.59 21 116.23 45 184.78-29.74l.75.69c-35.55 38.75-73.27 51.15-106.93 51.15z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m69.49 120.5c-21.44 0-45.67-5.73-71-22.27l.56-.86c47.65 31.1 91.4 23.69 119.72 12 31.23-12.9 53.23-34.74 57.35-40.82l.85.57c-1.86 2.77-8.26 9.79-18.27 17.76a167 167 0 0 1 -39.54 23.43 130.14 130.14 0 0 1 -49.67 10.19z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m610.88 141.57c-50 0-100.74-24-151-71.59l.7-.74c54.42 51.48 109.33 75.29 163.23 70.76 40-3.35 71.78-22.06 91.44-37.17 21.31-16.38 33.32-32.18 33.44-32.33l.82.62c-.12.16-12.19 16-33.61 32.51-19.77 15.2-51.77 34-92 37.4q-6.48.54-13.02.54z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m612.59 147.05c-52.33 0-106-25.9-159.74-77.14l.71-.74c57.56 54.88 115 80.59 170.73 76.42 72-5.38 118.75-58.85 131.37-75.1l.81.63c-12.68 16.34-59.69 70.08-132.1 75.5q-5.88.38-11.78.43z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m618.91 123.15c-1.46 0-2.92 0-4.4-.07-60.4-1.89-118-43.7-124.51-53.34l.85-.57c2.5 3.72 17.41 16.36 40.57 28.69 19.64 10.47 50 23.16 83.13 24.2 41.73 1.31 76.71-16 103.92-51.56l.81.62c-26.47 34.54-60.22 52.03-100.37 52.03z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m612.34 123.18c-37.23 0-75.89-17.81-115.17-53.11l.68-.76c44.09 39.62 87.36 57.1 128.63 52 45.45-5.65 76.67-38.57 84.86-50.78l.85.57c-.3.44-30.36 44.35-85.58 51.22a115.22 115.22 0 0 1 -14.27.86z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m884.16 123.2c-1.44 0-2.89 0-4.35-.07-63-2-117-45.1-121.72-52.14l.85-.57c2.47 3.68 17 16.08 39.59 28.09 19.15 10.21 48.8 22.58 81.31 23.6 41.71 1.31 76.79-16.21 104.25-52l.81.62c-26.71 34.82-60.59 52.47-100.74 52.47z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m868.42 123.68c-37.1 0-71.81-17.72-103.32-52.76l.76-.68c33.63 37.41 70.92 55 110.83 52.12 56-4 96.36-45.95 100.53-52.15l.85.57c-4.2 6.25-44.94 48.62-101.3 52.6q-4.2.3-8.35.3z"
              fill="#cfcfcf"
              transform="translate(2.67 -3.42)"
            />
            <path
              d="m215.73 64.84v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.78v1.1h1.21v-1.1h1.97v-4.96h-5.27v-1.15h-3.4v1.15h-5.21v-1.15h-3.4v1.15h-24.34v-1.15h-3.4v1.15h-5.22v-1.15h-3.4v1.15h-5.11v4.96h1.66v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.14h1.21v-1.14h5.77v1.1h1.21v-1.1h5.15l-.03 5.66h9.56l.03-5.66z"
              fill="#404040"
            />
            <path
              d="m1016.66 66.25v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.14h1.21v-1.14h5.77v1.08h1.21v-1.08h1.98v-4.98h-5.28v-1.14h-3.39v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-5.12v4.98h1.66v1.16h1.21v-1.16h5.78v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.08h1.21v-1.08h5.15v5.06h9.56v-5.06z"
              fill="#404040"
            />
            <path
              d="m750.91 66.67v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.78v1.1h1.21v-1.1h1.97v-4.97h-5.27v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.39v1.14h-5.12v4.97h1.66v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.14h1.21v-1.14h5.77v1.1h1.21v-1.1h5.15v4.64h9.56v-4.64z"
              fill="#404040"
            />
            <path
              d="m482.95 65.61v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.78v1.09h1.21v-1.09h1.97v-4.96h-5.27v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.39v1.14h-5.12v4.96h1.66v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15l-.03 3.86h9.56l.03-3.86z"
              fill="#404040"
            />
            <g transform="translate(2.67 -3.42)">
              <path
                d="m4154.39 153.47c-49.74 0-100.29-24-150.49-71.48l.7-.74c54.46 51.52 109.33 75.28 163 70.62 76.08-6.6 123.47-69.14 123.94-69.77l.82.61c-.12.16-12.06 16.06-33.3 32.57-19.6 15.24-51.37 34.13-91.37 37.6q-6.61.6-13.3.59z"
                fill="#cfcfcf"
              />
              <path
                d="m4140.2 157.12c-52.58 0-100.76-25.24-143.27-75.09l.78-.66c43.32 50.8 92.51 75.91 146.28 74.68 43.29-1 81.36-19.31 105.67-34.49 27-16.88 45.29-34.84 48.88-39.47l.81.63c-3.62 4.67-22 22.76-49.15 39.71-24.42 15.25-62.67 33.64-106.19 34.65-1.27.02-2.55.04-3.81.04z"
                fill="#cfcfcf"
              />
              <path
                d="m3619.57 151.88c-50 0-100.74-24-151-71.59l.7-.74c54.42 51.48 109.33 75.29 163.24 70.77 40-3.35 71.78-22.06 91.44-37.17 21.31-16.38 33.32-32.18 33.44-32.33l.82.62c-.12.16-12.19 16-33.61 32.51-19.77 15.2-51.77 34-92 37.4q-6.49.53-13.03.53z"
                fill="#cfcfcf"
              />
              <path
                d="m3621.29 157.36c-52.33 0-106-25.9-159.74-77.14l.71-.74c57.56 54.87 115 80.58 170.72 76.42 72-5.38 118.75-58.84 131.37-75.1l.81.63c-12.68 16.34-59.7 70.08-132.1 75.5q-5.88.42-11.77.43z"
                fill="#cfcfcf"
              />
              <path
                d="m3088.28 147.78c-49.74 0-100.54-24.22-151.2-72.14l.7-.74c55 52 110.12 76 163.79 71.28 74.49-6.55 120.29-67.28 120.75-67.89l.82.61c-.11.15-11.66 15.6-32.31 31.66-19.06 14.82-50 33.2-89.17 36.65-4.45.37-8.91.57-13.38.57z"
                fill="#cfcfcf"
              />
              <path
                d="m3089.61 153.8c-51.91 0-105.51-26-159.53-77.54l.71-.74c57.8 55.11 115.07 81 170.26 76.83 67.83-5.07 112.25-53.43 128.2-74l.07-.1.81.63-.07.1c-16 20.66-60.69 69.28-128.93 74.38q-5.76.45-11.52.44z"
                fill="#cfcfcf"
              />
              <path
                d="m4306 77.06v-1.14h-3.4v1.14h-5.22v-1.14h-3.38v1.14h-24.34v-1.14h-3.4v1.14h-5.26v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.22h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15v201.21a18.27 18.27 0 0 1 9.56-.41v-200.8h4.43v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.74v1.09h1.21v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m4038.75 76.29v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.39v1.14h-5.22v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.19h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.1v206.18a4.53 4.53 0 0 0 .07.74 55.71 55.71 0 0 1 7.37-.47h2.09a3 3 0 0 0 0-.31v-206.14h4.47v1.16h1.21v-1.16h5.79v1.11h1.21v-1.11h1.6v1.14h1.19v-1.14h5.77v1.09h1.23v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m3771.91 75.63v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.21v-1.19h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15v201.54a1.45 1.45 0 0 0 1.71 1.41 35.31 35.31 0 0 1 5.67-.45c.74 0 1.47 0 2.18.07v-202.57h4.43v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.81v1.09h1.21v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m3503.4 74.29v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.38v-1.14h-3.4v1.14h-5.22v-1.14h-3.38v1.14h-5.12v5h1.66v1.16h1.21v-1.19h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15s-.65 190-.32 204a.42.42 0 0 0 .63.36c2.24-1.39 5.73-.5 9.26-.92v-203.43h4.43v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m3236.62 73.19v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.4v1.14h-5.12v5h1.66v1.16h1.22v-1.19h5.77v1.11h1.23v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15v202.45c.54 0 1.09-.05 1.65-.05 3 0 5.58-.52 7.81.22l.09-202.61h4.43v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h2v-5z"
                fill="#404040"
              />
              <path
                d="m4151.34 140.91a129.84 129.84 0 0 1 -14.55-.83c-54.2-6.13-95.5-44-102.8-58l.91-.47c7.23 13.9 48.19 51.4 102 57.49a123.61 123.61 0 0 0 61.07-8.34c22.71-9.24 44.1-25.53 63.57-48.42l.78.66c-19.59 23-41.11 39.41-64 48.71a124.18 124.18 0 0 1 -46.98 9.2z"
                fill="#cfcfcf"
              />
              <path
                d="m4147.8 139.27c-37.31 0-73.19-19.23-106.78-57.26l.77-.68c35.53 40.23 73.6 59.3 113.18 56.68 28.89-1.91 53.06-15.33 68.26-26.25 16.72-12 28.24-25 31.2-29.41l.85.57c-3 4.46-14.62 17.57-31.45 29.67-15.31 11-39.66 24.52-68.79 26.44-2.42.16-4.84.24-7.24.24z"
                fill="#cfcfcf"
              />
              <path
                d="m3887.39 153c-49.7 0-100.24-24-150.42-71.48l.7-.74c54.48 51.54 109.33 75.3 163 70.62 76-6.63 123.3-69.16 123.77-69.79l.82.61c-.12.16-12 16.06-33.25 32.57-19.58 15.24-51.3 34.14-91.26 37.63-4.42.33-8.89.58-13.36.58z"
                fill="#cfcfcf"
              />
              <path
                d="m3888.64 156.41c-52 0-105.26-25.13-158.5-74.85l.7-.75c57.41 53.61 114.79 78.52 170.55 74.07 74.22-5.94 121.36-62.14 130.06-73.35l.81.63c-8.74 11.27-56.14 67.77-130.79 73.74q-6.42.51-12.83.51z"
                fill="#cfcfcf"
              />
              <path
                d="m3888.19 133.37q-5.31 0-10.71-.41c-58-4.39-105.18-43.88-110.38-51.61l.85-.57c5.15 7.66 51.92 46.81 109.6 51.16 43.75 3.3 83-13.66 116.71-50.41l.75.69c-31.14 33.97-67.01 51.15-106.82 51.15z"
                fill="#cfcfcf"
              />
              <path
                d="m3869.77 134c-31.18 0-67.69-11.16-95.64-52.64l.85-.57c34.79 51.64 83 55.87 117.28 50.33 51.51-8.33 92.24-40.22 94.8-49.39l1 .28c-3 10.83-46.24 42.14-95.63 50.13a143.18 143.18 0 0 1 -22.66 1.86z"
                fill="#cfcfcf"
              />
              <path
                d="m3604.47 134.06a150 150 0 0 1 -40-5.39c-36.62-10.18-60.84-32.24-65.84-48.77l1-.3c4.92 16.26 28.88 38 65.14 48.08 31.55 8.77 94.62 14.34 162.48-46.95l.69.76c-46.12 41.63-90.03 52.57-123.47 52.57z"
                fill="#cfcfcf"
              />
              <path
                d="m3603.63 129.81a133.17 133.17 0 0 1 -44.79-7.31c-23.8-8.52-40.2-20.19-53.28-42.43l.88-.52c12.95 22 29.17 33.56 52.74 42 19.85 7.1 43.28 9 67.74 5.57 21.68-3.06 43.3-10.2 60.88-20.09 16.69-9.39 28.09-17.37 32.36-25.67l1 .25c-4.59 8.71-15.91 16.78-32.85 26.31-17.69 10-39.44 17.13-61.24 20.21a166.46 166.46 0 0 1 -23.44 1.68z"
                fill="#cfcfcf"
              />
              <path
                d="m4298.43 106.66c-9.16-7.08-18.43-15-27.56-23.68l.7-.74c9.1 8.61 18.35 16.55 27.48 23.61z"
                fill="#cfcfcf"
              />
              <path
                d="m4297.53 111.71a393.28 393.28 0 0 1 -33.67-28.8l.71-.74a391.13 391.13 0 0 0 33.58 28.72z"
                fill="#cfcfcf"
              />
              <path
                d="m3352.28 150.66c-49.67 0-100.21-24-150.44-71.53l.7-.74c54.54 51.59 109.4 75.37 163.07 70.66 75.79-6.64 122.88-69 123.35-69.63l.82.61c-.12.16-12 16-33.11 32.48-19.5 15.2-51.11 34.06-91 37.55q-6.67.6-13.39.6z"
                fill="#cfcfcf"
              />
              <path
                d="m3356.14 156.63a172.29 172.29 0 0 1 -47.55-6.85c-37.85-10.83-76.21-34.6-114-70.66l.71-.74c37.7 35.94 75.92 59.63 113.61 70.42 31 8.88 61.74 9.08 91.31.61 56.92-16.3 93.34-60.74 95.67-69.8l1 .25c-2.36 9.17-39.08 54.12-96.38 70.53a160.57 160.57 0 0 1 -44.37 6.24z"
                fill="#cfcfcf"
              />
              <path
                d="m3353.58 136.63q-4.73 0-9.53-.34c-54.62-3.82-102-39.12-112.09-57l.89-.5c10 17.69 57 52.67 111.27 56.46a123 123 0 0 0 58.58-9.79c20.94-9.18 39.84-24.65 56.18-46l.81.62c-16.44 21.46-35.48 37-56.58 46.29a122.47 122.47 0 0 1 -49.53 10.26z"
                fill="#cfcfcf"
              />
              <path
                d="m3351.63 128.33c-4 0-7.93-.16-11.64-.44-47.09-3.57-89.22-28.15-101.24-48.89l.88-.51c11.89 20.51 53.67 44.84 100.43 48.38 31.69 2.4 76.82-3.87 111.75-47.37l.8.64c-31.12 38.75-70.25 48.19-100.98 48.19z"
                fill="#cfcfcf"
              />
            </g>
            <path
              d="m4026.73 77.84v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.78v1.1h1.21v-1.1h1.97v-4.96h-5.27v-1.15h-3.4v1.15h-5.21v-1.15h-3.4v1.15h-24.34v-1.15h-3.4v1.15h-5.22v-1.15h-3.39v1.15h-5.12v4.96h1.66v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.14h1.21v-1.14h5.77v1.1h1.21v-1.1h5.15l-.02 5.83h9.56l.02-5.83z"
              fill="#404040"
            />
            <path
              d="m3759.88 77.19v1.15h1.21v-1.15h5.78v1.09h1.21v-1.09h1.6v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h1.98v-4.97h-5.27v-1.14h-3.4v1.14h-5.22v-1.14h-3.39v1.14h-24.35v-1.14h-3.39v1.14h-5.22v-1.14h-3.4v1.14h-5.12v4.97h1.67v1.15h1.21v-1.15h5.77v1.09h1.21v-1.09h1.6v1.14h1.21v-1.14h5.78v1.09h1.21v-1.09h5.15v4.95h9.56v-4.95z"
              fill="#404040"
            />
            <path
              d="m3491.38 75.86v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h1.61v1.14h1.21v-1.14h5.77v1.08h1.21v-1.08h1.97v-4.98h-5.27v-1.13h-3.39v1.13h-5.22v-1.13h-3.4v1.13h-24.34v-1.13h-3.4v1.13h-5.21v-1.13h-3.4v1.13h-5.12v4.98h1.66v1.14h1.21v-1.14h5.78v1.09h1.2v-1.09h1.61v1.14h1.21v-1.14h5.77v1.08h1.21v-1.08h5.15v5.28h9.56v-5.28z"
              fill="#404040"
            />
            <path
              d="m4293.95 78.61v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.78v1.09h1.21v-1.09h1.97v-4.97h-5.27v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.22v-1.14h-3.39v1.14h-5.12v4.97h1.66v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.14h1.21v-1.14h5.77v1.09h1.21v-1.09h5.15v5.7h9.56v-5.7z"
              fill="#404040"
            />
            <path
              d="m3224.59 74.75v1.16h1.21v-1.16h5.78v1.09h1.21v-1.09h1.6v1.14h1.21v-1.14h5.77v1.08h1.21v-1.08h1.98v-4.97h-5.27v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-24.35v-1.14h-3.39v1.14h-5.22v-1.14h-3.4v1.14h-5.12v4.97h1.67v1.16h1.21v-1.16h5.77v1.09h1.21v-1.09h1.6v1.14h1.21v-1.14h5.78v1.08h1.21v-1.08h5.15l-.02 4.09h9.56l.02-4.09z"
              fill="#404040"
            />
            <path
              d="m2922.59 71.42v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.6v1.14h1.21v-1.14h5.78v1.08h1.21v-1.08h1.97v-4.98h-5.27v-1.13h-3.39v1.13h-5.22v-1.13h-3.4v1.13h-24.34v-1.13h-3.4v1.13h-5.22v-1.13h-3.39v1.13h-5.12v4.98h1.66v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.14h1.21v-1.14h5.77v1.08h1.21v-1.08h5.15l-.01 4.1h9.56l.01-4.1z"
              fill="#404040"
            />
            <path
              d="m1319.59 66.73v1.16h1.21v-1.16h5.77v1.11h1.21v-1.11h1.61v1.16h1.21v-1.16h5.77v1.1h1.21v-1.1h1.97v-4.96h-5.27v-1.14h-3.39v1.14h-5.22v-1.14h-3.4v1.14h-24.34v-1.14h-3.4v1.14h-5.21v-1.14h-3.4v1.14h-5.12v4.96h1.66v1.16h1.21v-1.16h5.78v1.11h1.21v-1.11h1.6v1.16h1.21v-1.16h5.77v1.1h1.21v-1.1h5.15l.01 5.55h9.56l-.01-5.55z"
              fill="#404040"
            />
            <path
              d="m1311.17 0-13.51 24.74 10.35-.53-2.12 23.27 12.82-29.55-9.67.49z"
              fill="#eda302"
            />
            <path
              d="m1343.59 25.92-15.12 8.77 5.66 3.04-8.59 11.66 16.29-11.55-5.29-2.85z"
              fill="#eda302"
            />
            <path
              d="m1276.78 24.08.78 17.45 5.32-3.6 6.34 13.02-2.69-19.79-4.97 3.37z"
              fill="#eda302"
            />
            <path
              d="m2113.31 4.26-13.51 24.74 10.34-.52-2.11 23.26 12.82-29.54-9.68.48z"
              fill="#eda302"
            />
            <path
              d="m2145.72 30.18-15.11 8.77 5.66 3.04-8.6 11.67 16.29-11.56-5.29-2.84z"
              fill="#eda302"
            />
            <path
              d="m2078.91 28.34.78 17.46 5.32-3.6 6.35 13.02-2.7-19.8-4.97 3.38z"
              fill="#eda302"
            />
            <path
              d="m2914.97 4.82-13.52 24.73 10.35-.53-2.12 23.28 12.83-29.55-9.69.48z"
              fill="#eda302"
            />
            <path
              d="m2947.38 30.73-15.11 8.78 5.66 3.04-8.61 11.65 16.29-11.54-5.29-2.86z"
              fill="#eda302"
            />
            <path
              d="m2880.57 28.89.77 17.45 5.32-3.6 6.36 13.03-2.7-19.79-4.98 3.36z"
              fill="#eda302"
            />
            <path
              d="m3751.45 5.26-13.52 24.74 10.35-.53-2.12 23.27 12.83-29.55-9.69.49z"
              fill="#eda302"
            />
            <path
              d="m3783.86 31.18-15.12 8.77 5.67 3.04-8.61 11.66 16.29-11.55-5.29-2.85z"
              fill="#eda302"
            />
            <path
              d="m3717.05 29.34.77 17.45 5.32-3.6 6.35 13.02-2.69-19.79-4.98 3.37z"
              fill="#eda302"
            />
          </svg>
          <Footer />
        </>
      )}
    />
  );
};

Layout.defaultProps = {
  hasSideBar: true,
};

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  hasSideBar: PropTypes.bool,
  initialContext: PropTypes.string,
};

export default Layout;
